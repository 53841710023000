




























































































































































import Vue from 'vue';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/functions';
import { db, storage } from '@/main';
import algoliasearch from 'algoliasearch';
import constants from '../constants/constants';
import {
  ContractType,
  CurrencyType,
  PerkType,
  Position,
  WorkConditionsType
} from '@/models/position.model';
import { Practice } from '@/models/practice.model';
import router from '@/router';
import { User } from '@/models/user.model';

export default Vue.extend({
  name: 'Positions',
  components: {
    SalaryValue: () => import('@/components/SalaryValue.vue')
  },
  data() {
    return {
      router: router,
      vfOptions: {
        autoplay: true
      },
      vfTransitions: ['fade', 'cube', 'book', 'wave'],
      benched: 5,
      selectedPositionUid: null as null | string,
      snackbar: false,
      snackbarColor: '',
      snackbarText: '',
      showEditModalValue: false,
      showDetailsModalValue: false,
      footerProps: { 'items-per-page-options': [20, 50, 100, 200, 500, 1000] },
      headers: [
        { value: 'title', text: 'Title', sortable: true },
        { value: 'contractType', text: 'Contract Type', sortable: true },
        { value: 'minSalary', text: 'Min Salary', sortable: true },
        { value: 'maxSalary', text: 'Max Salary', sortable: true },
        {
          value: 'conditionsMatch',
          text: 'Conditions Match',
          sortable: false,
          align: 'center'
        },
        {
          value: 'salaryMatch',
          text: 'Salary Match',
          sortable: false,
          align: 'center'
        },
        {
          value: 'skillsMatch',
          text: 'Skills Match',
          sortable: false,
          align: 'center'
        },
        { value: 'city', text: 'City', sortable: false },
        { value: 'practice', text: 'Practice', sortable: false },
        { value: 'distance', text: 'Travel Distance', sortable: true },
        { value: 'duration', text: 'Travel Duration', sortable: true }
      ],
      rules: {
        required: (value: string) => !!value || 'Required.'
      },
      options: {} as any,
      page: 1,
      totalRows: 1,
      perPage: { value: 50, text: '50 per page' },
      position: {} as Position,
      positions: [] as Position[],
      isPositionsLoading: false,
      candidates: [] as User[],
      client: algoliasearch(
        constants.ALGOLIA.APP_ID,
        constants.ALGOLIA.SEARCH_KEY
      ),
      topVisible: {} as firebase.firestore.QueryDocumentSnapshot<
        firebase.firestore.DocumentData
      >,
      bottomVisible: {} as firebase.firestore.QueryDocumentSnapshot<
        firebase.firestore.DocumentData
      >,
      isBusy: false,
      headingText: 'Positions',
      previousPageNumber: 1,
      dataListener: (null as null | {}) as () => void,
      practice: null as null | Practice,
      practices: {},
      candidateTravelDistances: {},
      candidateTravelDurations: {},
      practiceLogoUrls: {},
      profileImageUrls: {},
      practiceImageUrlsIndexes: {},
      topPerks: {},
      annualSalaryContracts: ['Full-time', 'Part-time'],
      workConditionTypes: Object.values(WorkConditionsType),
      contractTypes: Object.values(ContractType) as string[],
      perkTypes: Object.values(PerkType) as string[],
      currencyTypes: Object.values(CurrencyType),
      index: null
    };
  },
  methods: {
    updateShowDetailsModalValue(value: boolean) {
      this.showDetailsModalValue = value;
    },
    initData() {
      this.getTotalCandidateCount();
      this.getData();
    },
    getTotalCandidateCount() {
      // const filter = `practiceUid:${this.$store.state.currentUser.practiceUids[0]}`;
      this.algoliaClient
        .search('', {
          // filters: filter
        })
        .then(({ nbHits }: any) => {
          this.totalRows = nbHits > 0 ? nbHits : 1;
        })
        .catch((err: any) => {
          console.log(err);
          return null;
        });
    },
    getData() {
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      this.isBusy = true;
      let query: any = this.mainQuery;
      let order = 'asc' as 'asc' | 'desc';
      let sort = 'name';
      if (sortBy && sortBy.length > 0) {
        sort = sortBy[0];
      }
      if (sortDesc !== undefined && sortDesc.length > 0) {
        order = sortDesc[0] ? 'desc' : 'asc';
      }
      query = query.orderBy(sort, order);
      const pageDiff = (page ?? 1) - this.previousPageNumber;
      this.previousPageNumber = page ?? 1;
      switch (pageDiff) {
        case 0:
          query = query.limit(itemsPerPage ?? this.perPage.value);
          break;
        case 1:
          query = query
            .startAfter(this.bottomVisible)
            .limit(itemsPerPage ?? this.perPage.value);
          break;
        case -1:
          query = query
            .endBefore(this.topVisible)
            .limitToLast(itemsPerPage ?? this.perPage.value);
          break;
        default:
          query = query.limit(itemsPerPage ?? this.perPage.value);
      }
      if (this.dataListener) {
        this.dataListener();
      }
      this.dataListener = query.onSnapshot(
        (snapshot: firebase.firestore.QuerySnapshot) => {
          this.isBusy = false;
          if (snapshot.docs.length > 0) {
            console.log('this.candidates: ', this.candidates);

            this.candidates = snapshot.docs.map(value => value.data() as User);
            this.candidates.forEach(async candidate => {
              if (candidate.profileImageRef) {
                Vue.set(
                  this.profileImageUrls,
                  candidate.uid,
                  await storage.ref(candidate.profileImageRef).getDownloadURL()
                );
              }
            });
            this.candidates = this.candidates.filter((candidate: User) => {
              return (
                this.position?.workConditionTypes?.every(value =>
                  candidate.workConditionTypes?.includes(value)
                ) &&
                this.position.vetAnimalTypes?.every(value =>
                  candidate.vetAnimalTypes?.includes(value)
                )
              );
            });
            this.getTravelDistances();
            this.topVisible = snapshot.docs[0];
            this.bottomVisible = snapshot.docs[snapshot.docs.length - 1];
          }
        },
        (error: Error) => {
          console.error(error);
        }
      );
    },
    getPositions(): Promise<void | Position[]> {
      this.isPositionsLoading = true;
      return db
        .collection('positions')
        .where('practiceUid', '==', this.practice?.uid)
        .get()
        .then((snapshot: firebase.firestore.QuerySnapshot) => {
          this.positions = snapshot.docs.map(doc => doc.data() as Position);
          this.position = this.positions[0];
          if (this.positions.length > 0) {
            this.selectedPositionUid = this.positions[0].uid;
          }
          this.isPositionsLoading = false;
        })
        .catch(error => {
          console.error(error);
          this.isPositionsLoading = false;
        });
    },
    getPractice(): Promise<void | Practice> {
      return db
        .collection('practices')
        .doc(this.$store.state.currentUser.activePractice.uid)
        .get()
        .then(doc => {
          this.practice = doc.data() as Practice;
        });
    },
    getTravelDistances() {
      this.candidateTravelDistances = {};
      this.candidateTravelDurations = {};
      this.candidates.forEach(async (candidate: User) => {
        const result = await this.getDistance(candidate.googlePlaceId);
        Vue.set(
          this.candidateTravelDistances,
          candidate.uid,
          result.data.distance.text
        );
        Vue.set(
          this.candidateTravelDurations,
          candidate.uid,
          result.data.duration.text
        );
      });
    },
    getDistance(userPlaceId: string): Promise<any> {
      return firebase
        .functions()
        .httpsCallable('getDistanceBetweenPlaces')({
          origin: this.practice?.googlePlaceId,
          destination: userPlaceId
        })
        .then(result => {
          return result;
        })
        .catch(error => {
          console.error(error);
          return error;
        });
    },
    updatePosition() {
      this.$confirm(
        'This will update your live position to reflect the filters  you have chosen. Would you like to proceed?'
      ).then(res => {
        if (res) {
          db.collection('positions')
            .doc(this.position.uid)
            .set(this.position, { merge: true });
        }
      });
    },
    selectedRow(item: Position) {
      this.position = item;
      this.showDetailsModalValue = true;
    },
    showEditModal() {
      this.showEditModalValue = true;
    },
    applied() {
      this.showSnackbar(
        'Successfuly applied. The practice will be in touch if you are successful',
        'success'
      );
    },
    convertCurrencyToDollarBase(currency: string, value: number): number {
      switch (currency) {
        case CurrencyType.usd:
          return value;
        case CurrencyType.gbp:
          return value * 0.72;
        case CurrencyType.eur:
          return value * 0.84;
        case CurrencyType.zar:
          return value * 15.32;
        case CurrencyType.aud:
          return value * 1.36;
        case CurrencyType.nzd:
          return value * 1.43;
        default:
          return value;
      }
    },
    showSnackbar(text: string, color: string) {
      this.snackbarText = text;
      this.snackbarColor = color;
      this.snackbar = true;
    }
  },
  watch: {
    selectedPositionUid() {
      if (this.selectedPositionUid) {
        this.position = this.positions.find(
          value => value.uid === this.selectedPositionUid
        ) as Position;
        this.initData();
      }
    },
    selectedConditions() {
      this.initData();
    },
    selectedContractTypes() {
      this.initData();
    },
    selectedPerkTypes() {
      this.initData();
    },
    maxDailySalary() {
      this.initData();
    },
    maxAnnualSalary() {
      this.initData();
    },
    currency() {
      this.initData();
    },
    weeksAnnualLeave() {
      this.initData();
    },
    options: {
      handler() {
        this.getData();
      },
      deep: true
    }
  },
  async created() {
    await this.getPractice();
    await this.getPositions();
    this.initData();
  },
  beforeDestroy() {
    if (this.dataListener) {
      this.dataListener();
    }
  },
  computed: {
    algoliaClient(): any {
      return this.client.initIndex(constants.ALGOLIA.INDEXES.USERS);
    },
    mainQuery() {
      const queryRef = db
        .collection('users')
        .where('isCandidate', '==', true)
        .where('isVisible', '==', true)
        .where('isApproved', '==', true);
      return queryRef;
    }
  }
});
